<template>
  <div class="mainBox page-container">
    <Breadcrumb></Breadcrumb>

    <!--  操作区-->
    <el-card>
      <el-form
        :inline="true"
        :model="selectInfo"
        class="demo-form-inline"
        size="small"
        ref="selectOptionsFormRef"
      >
        <el-form-item label="患者姓名" prop="keyword">
          <el-input
            v-model="selectInfo.keyword"
            placeholder="请输入患者姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="医院名称" prop="hospitalId">
          <el-select
            v-model="selectInfo.hospitalId"
            placeholder="选择医院"
            filterable
            remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in hospitalList"
              :key="index"
              :label="item.hospitalName"
              :value="item.hospitalId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="医生工作室" prop="groupId">
          <el-select
            v-model="selectInfo.groupId"
            placeholder="选择工作室"
            filterable
            remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in doctorOfficeList"
              :key="index"
              :label="item.groupName"
              :value="item.groupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联医生" prop="assistantId">
          <el-select
            v-model="selectInfo.assistantId"
            placeholder="选择医生"
            filterable
            remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in assistantList"
              :key="index"
              :label="item.assistantName"
              :value="item.assistantId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="resetSelectOptions"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <div class="formregion">
      <el-tabs @tab-click="handleClick" v-model="activeName" type="card">
        <el-tab-pane label="待跟进" name="1">
          <el-table
            :data="toassignePatients"
            border
            style="width: 100%"
            size="mini"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="userName"
              label="患者姓名"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="hospitalName"
              label="医院名称"
              align="center"
            ></el-table-column>
            <el-table-column prop="groupName" label="医生工作室" align="center">
              <template slot-scope="socpe">
                <span v-if="socpe.row.groupName == null">-</span>
                <span v-else>{{ socpe.row.groupName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="assistantName"
              label="关联医生"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="questionnaireNum"
              label="待跟进问卷数"
              width="120"
              align="center"
            ></el-table-column>

            <el-table-column width="100" label="操作列" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="toFillQuestionnaire(scope.row)"
                  type="text"
                  size="small"
                  >随访</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="2">
          <el-table
            :data="dealedPatientList"
            border
            style="width: 100%"
            size="mini"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="userName"
              label="患者姓名"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="hospitalName"
              label="医院名称"
              align="center"
            ></el-table-column>
            <el-table-column prop="groupName" label="医生工作室" align="center">
              <template slot-scope="socpe">
                <span v-if="socpe.row.groupName == null">-</span>
                <span v-else>{{ socpe.row.groupName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="assistantName"
              label="关联医生"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="questionnaireNum"
              label="已完成问卷数"
              width="120"
              align="center"
            ></el-table-column>

            <el-table-column width="100" label="操作列" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="tofollowdetails(scope.row)"
                  type="text"
                  size="small"
                  >随访详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="pag_warpper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pagenum"
          :page-sizes="[10, 20, 50]"
          :page-size="pagesize"
          layout="slot, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin-left: 30px"
        >
          <span class="total"
            >共 {{ total }} 条数据，共
            {{ Math.ceil(total / pagesize) }} 页</span
          >
        </el-pagination>
      </div>
    </div>
    <!--    添加工单对话框-->
    <el-dialog
      title="添加工单"
      :visible.sync="addOrderDialogVisiable"
      width="30%"
      @close="addOrderDialogClose"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <!--      内容主体区-->
      <el-form :model="addOrderForm" ref="addOrderFormRef" label-width="80px">
        <el-form-item label="医生工作室" prop="office" label-width="100px">
          <el-input v-model="addOrderForm.office" disabled></el-input>
        </el-form-item>
        <el-form-item label="患者姓名" prop="name" label-width="100px">
          <el-input v-model="addOrderForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="事件描述" prop="des" label-width="100px">
          <el-input
            v-model="addOrderForm.des"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrderDialogVisiable = false">取 消</el-button>
        <el-button type="primary" @click="addOrderConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogFormVisibleEditBox"
      width="600px"
      title="随访提醒"
    >
      <EditInfo
        v-if="dialogFormVisibleEditBox"
        :recordList="recordList"
        :remindParams="remindParams"
        @updateList="updateList"
      ></EditInfo>
      <!--      :groupIdArr="groupIdArr"-->
    </el-dialog>
    <el-dialog
      :visible.sync="relationsDialog"
      width="360px"
      :show-close="false"
      :modal="false"
      class="callAndUnbundleBox"
    >
      <div class="callAndUnbundleCon">
        <div class="title" v-for="(item, index) in relationsList" :key="index">
          <div>{{ item.name }} :{{ item.phone }}</div>
          <span @click="telNum(item.phone)" style="cursor: pointer">呼叫</span>
        </div>
        <div class="associatedBtn">
          <div class="associatedCancel" @click="relationsDialog = false">
            取消
          </div>
          <!--          <div class="associatedSure" @click="callAndUnbundleSure()">确定</div>-->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EditInfo from "./components/EditInfo.vue";

import { timeMode } from "../../tools/utils";
import { selectparams } from "../../mixins/selectparams";
import { callpatients } from "../../mixins/callpatients";
export default {
  name: "taskcenter",
  mixins: [selectparams, callpatients],
  components: {
    EditInfo,
  },
  data() {
    return {
      timeMode,
      dialogFormVisibleEditBox: false,
      //添加工单管理对话框
      addOrderDialogVisiable: false,
      relationsDialog: false,
      //添加工单对话框信息内容
      addOrderForm: {
        name: "",
        office: "",
        des: "",
        assistantId: "",
      },
      //总条数
      total: 0,
      doctorOfficeList: [],
      assistantList: [],
      hospitalList: [],
      value: "",
      //查询数据
      selectInfo: {
        keyword: "",
        groupId: "",
        hospitalId: "",
        assistantId: "",
      },
      recordList: [],
      pagenum: 1,
      //当前每页展示的数据条数
      pagesize: 10,
      activeName: "1",
      //待分配列表患者
      toassignePatients: [],
      //已分配患者列表
      dealedPatientList: [],
      //当前操作患者信息
      currentPatientInfo: {},
      //提醒参数
      remindParams: {},
    };
  },
  created() {},
  //
  beforeRouteLeave(to, from, next) {
    console.log(to.path, "==========================");
    if (to.path === "/reviewManagement") {
      sessionStorage.removeItem("selectParams");
      sessionStorage.removeItem("actionType");
    }
    next();
  },
  mounted() {
    if (sessionStorage.getItem("pageInfo")) {
      let pageInfo = JSON.parse(sessionStorage.getItem("pageInfo"));
      this.pagenum = pageInfo.pagenum;
      this.pagesize = pageInfo.pagesize;
      this.total = pageInfo.total;
      sessionStorage.removeItem("pageInfo");
    }
    if (JSON.parse(sessionStorage.getItem("selectParams"))) {
      this.selectInfo = JSON.parse(sessionStorage.getItem("selectParams"));
      sessionStorage.removeItem("selectParams");
    }
    if (sessionStorage.getItem("actionType")) {
      this.activeName = sessionStorage.getItem("actionType");
      sessionStorage.removeItem("actionType");
    }
    this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
  },
  //总条数
  computed: {},
  methods: {
    //重置筛选项
    resetSelectOptions() {
      this.$refs.selectOptionsFormRef.resetFields();
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //查询按钮
    async onSubmit() {
      console.log(this.selectInfo, "查询参数============");
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //获取待处理患者数据列表
    async getPatientsList(params) {
      console.log(params, "待处理患者参数==================");
      const res = await this.$http.getTaskCenterList(params);
      console.log(res, "未处理患者===========================");
      this.selectInfo.pagesize = res.result.data.rows;
      this.total = res.result.data.records;
      this.pagenum = res.result.data.page;
      if (res.code !== 200) {
        return this.$message.error("数据获取失败!请稍后再试");
      } else {
        this.toassignePatients = res.result.data.data;
        console.log(this.toassignePatients);
        // this.$message.success('数据获取成功')
      }
    },
    //获取已处理患者数据列表
    async getDealedPatients(params) {
      console.log(params, "已处理患者参数==================");
      const res = await this.$http.getDealedPatiensList(params);
      console.log(res, "已处理患者================================");
      this.selectInfo.pagesize = res.result.data.rows;
      this.total = res.result.data.records;
      this.pagenum = res.result.data.page;
      if (res.code !== 200) {
        return this.$message.error("数据获取失败!请稍后再试");
      } else {
        this.dealedPatientList = res.result.data.data;
      }
    },
    //获取工作室列表
    async getAllOffices() {
      const res = await this.$http.getOfficeList();
      this.doctorOfficeList = res.result.data;
    },
    //获取待处理患者数据默认列表
    getDefalutPatientList(selectInfo, page, pageSize) {
      let _params = {
        customerServerId: this.userInfo.customerServerId,
        userInfo: selectInfo.keyword,
        groupId: selectInfo.groupId,
        hospitalId: selectInfo.hospitalId,
        assistantId: selectInfo.assistantId,
        page,
        pageSize,
      };
      console.log(_params);
      if (this.activeName == "1") {
        this.getPatientsList(_params);
      } else if (this.activeName == "2") {
        this.getDealedPatients(_params);
      }
    },
    handleClick() {
      //获取默认数据列表
      this.pagenum = 1;
      this.pagesize = 10;
      //重置表单
      this.$refs.selectOptionsFormRef.resetFields();
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //提醒患者
    alertPatient(row) {
      this.debounceAlertPatient(row);
    },
    //提醒患者防抖
    debounceAlertPatient(row) {
      console.log(row);
      let _params = {
        followUpId: row.followUpId,
        status: row.status,
      };
      //提醒患者请求(后续再测)
      this.$http
        .remindPatient(_params)
        .then((res) => {
          console.log(
            res,
            "===========每次提醒需间隔4个小时============提醒患者"
          );
          if (res.result.code == 1) {
            this.$message.success("提醒成功!");
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.indexOf("每次提醒需间隔4个小时") != -1
          ) {
            this.$message.error("每四个小时只能提醒一次!");
          }
        });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      // this.toPages();
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      // this.toPages();
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //添加工单对话框打开
    goAddOrder(value) {
      this.addOrderDialogVisiable = true;
      this.addOrderForm.name = value.name;
      this.addOrderForm.office = value.groupName;
      this.addOrderForm.assistantId = value.assistantId;
      this.currentPatientInfo = value;
    },
    //添加工单对话框关闭事件
    addOrderDialogClose() {
      this.$refs.addOrderFormRef.resetFields();
    },
    //确认添加工单
    async addOrderConfirm() {
      //隐藏对话框
      if (this.addOrderForm.des == "")
        return this.$message.error("请先填写事件描述");
      const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      let params = {
        followUpId: this.currentPatientInfo.followUpId,
        customerServerId: userInfo.customerServerId,
        groupId: this.currentPatientInfo.groupId,
        userId: this.currentPatientInfo.userId,
        problemDescription: this.addOrderForm.des,
        assistantId: this.addOrderForm.assistantId,
        workOrderType: 0,
      };
      //添加工单请求
      const res = await this.$http.addWorkOrder(params);
      console.log(params);
      console.log(res, "请求结果=========================");
      if (res.result.msg == "success") {
        this.$message.success("添加成功!");
      } else {
        this.$message.error("添加失败!");
      }
      this.addOrderDialogVisiable = false;
    },
    tofollowdetails(info) {
      sessionStorage.setItem("selectParams", JSON.stringify(this.selectInfo));
      sessionStorage.setItem("actionType", this.activeName);
      let pageInfo = {
        pagenum: this.pagenum,
        pagesize: this.pagesize,
        total: this.total,
      };
      sessionStorage.setItem("pageInfo", JSON.stringify(pageInfo));
      // this.$router.push({ name: "followdetails", query: info });
      this.$router.push({ name: "FollowUpDetail", query: info });
    },
    toFillQuestionnaire(info) {
      sessionStorage.setItem("selectParams", JSON.stringify(this.selectInfo));
      sessionStorage.setItem("actionType", this.activeName);
      let pageInfo = {
        pagenum: this.pagenum,
        pagesize: this.pagesize,
        total: this.total,
      };
      sessionStorage.setItem("pageInfo", JSON.stringify(pageInfo));
      // this.$router.push({ name: "fillquestionnaire", query: info });
      this.$router.push({ name: "FollowUpDetail", query: info });
    },
    remindRelations(info) {
      console.log(info);
      let params = {
        remindId: info.followUpId,
        remindType: 0,
      };
      this.remindParams = {
        remindId: info.followUpId,
        remindType: 0,
      };
      this.$http.getRemindRecords(params).then((res) => {
        console.log("///", res);
        if (res.result.data.length !== 0) {
          res.result.data.forEach((item, index) => {
            console.log(this.timeMode(item.remindTime));
            item["title"] =
              this.timeMode(item.remindTime).dateMin + ` 第${index + 1}次提醒`;
          });
        }
        console.log(res);
        this.recordList = res.result.data;
        console.log(this.recordList);
        this.dialogFormVisibleEditBox = true;
      });
    },
    updateList() {
      this.dialogFormVisibleEditBox = false;
    },
  },
};
</script>

<style scoped lang="less">
.mainBox {
  display: flex;
  flex-direction: column;
  /*height: 100%*/
}
/*.el-form {*/
/*  margin-left: 20px;*/
/*}*/
//.el-form-item {
//  margin-right: 30px;
//}
/*.el-tabs {*/
/*  margin-left: 30px;*/
/*}*/
.el-tab-pane {
  max-height: 560px;
  overflow-y: auto;
}
/deep/.el-table {
  margin-top: 0;
}
.formregion {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px;
  flex: 1;
  box-sizing: border-box;
  padding: 16px 20px;
}
.pag_warpper {
  background-color: #ffffff;
  height: 80px;
  padding: 20px;
  border-radius: 0 0 0 10px;
}
.el-card {
  flex-shrink: 0;
}
.total {
  margin-right: 24px;
  font-weight: 400 !important;
  color: #606266;
}
/deep/.callAndUnbundleBox {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 30px;
    box-sizing: border-box;
    .callAndUnbundleCon {
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 8px;
        span {
          color: #409eff;
        }
      }
      .associatedBtn {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .associatedCancel {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .associatedSure {
          width: 120px;
          height: 40px;
          background: #4facff;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
